import * as React from 'react';
import Header from './Header';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

interface State {
    height: string;
    weight: string;
}

const PaperMain = styled(Paper)(({theme}) => ({
    padding: '1em',
    marginBottom: '1em',
    color: theme.palette.text.secondary,
}));

function PageBmi() {
    const [values, setValues] = React.useState<State>({
        height: '172',
        weight: '50',
    });

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({
          ...values, [prop]: event.target.value
        });
    };

    const heightMeter = Number(values.height) / 100.0;

    const bmi = (Number(values.weight) / (heightMeter * heightMeter)).toFixed(1);

    const bestWeight: number = (heightMeter * heightMeter) * 22;
    const bestWeightLow: number = (heightMeter * heightMeter) * 18.5;
    const bestWeightHigh: number = (heightMeter * heightMeter) * 25;

    console.log(Number(values.height));
    console.log(bmi);

    return (
        <div style={{ backgroundColor: '#f0f0f0' }}>
            <Header></Header>

            <br />
            <PaperMain variant="outlined" sx={{ p: 2, margin: 'auto', width: '80vw', maxWidth: 1200, minHeight: '70vh', flexGrow: 1 }} >
                <Typography component="h1" variant="h6">
                    BMI計算機
                </Typography>
                身長と体重からBMI(Body Mass Index)と適正体重を計算します
                <br />
                <br />

                <Card variant="outlined">
                    <CardContent>
                        <Typography component="h1" variant="h6">
                            条件入力
                        </Typography>

                        <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '12ch' }}>
                            <Input
                                id="bmi-height"
                                value={values.height}
                                onChange={handleChange('height')}
                                endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                                aria-describedby="standard-height-helper-text"
                                inputProps={{
                                    'aria-label': '身長',
                                }}
                            />
                            <FormHelperText id="standard-weight-helper-text">身長</FormHelperText>
                        </FormControl>

                        <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '12ch' }}>
                            <Input
                                id="bmi-weight"
                                value={values.weight}
                                onChange={handleChange('weight')}
                                endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                                aria-describedby="standard-weight-helper-text"
                                inputProps={{
                                    'aria-label': '体重',
                                }}
                            />
                            <FormHelperText id="standard-weight-helper-text">体重</FormHelperText>
                        </FormControl>
                    </CardContent>
                </Card>
                <br />
                <Card variant="outlined">
                    <CardContent>
                        <Typography component="h1" variant="h6">
                            計算結果
                        </Typography>
                        <br />

                        BMI: {bmi} <br />

                        適正体重: {bestWeight.toFixed(1)} kg ( {bestWeightLow.toFixed(1)} 〜 {bestWeightHigh.toFixed(1)}kg)
                    </CardContent>
                </Card>
            </PaperMain>
            <br />
        </div>
    );
}

export default PageBmi;