import React from 'react';
import { blueGrey } from '@mui/material/colors';

const styleTimePanel = {
  textAlign: 'center' as 'center',
  fontSize: '120px',
  color: blueGrey[800],
}

type Props = {
  restTimeSec: number,
}

const TimePanel: React.FC<Props> = ({restTimeSec}) => {
  const getSeconds = (num: number) => {
    return prependZero(num % 60);
  }

  const getMinutes = (num: number) => {
    return prependZero(Math.floor(num / 60) % 60);
  }
  const getHours = (num: number) => {
    return prependZero(Math.floor(num / 3600));
  }

  const getTimeString = (restTimeSec: number) => {
    const sec = (restTimeSec < 0) ? 0 : restTimeSec;
    // console.log(sec);
    return getHours(sec) + ':' + getMinutes(sec) + ':' + getSeconds(sec);
  }

  const prependZero = (n: number) => {
    if (n >= 10) {
      return n;
    }
    return '0' + n;
  }

  return (
    <React.Fragment>
      <div style={styleTimePanel}>
        <div className='current-time'>
          {getTimeString(restTimeSec)}
        </div>
      </div>
    </React.Fragment>
  );
}

export {TimePanel};
