import React, {useState, useEffect} from 'react';
import Header from './Header';
import {Paper, styled, Typography} from '@mui/material';
import {blueGrey} from '@mui/material/colors';

const Container = styled(Paper)(() => ({
  backgroundColor: 'black',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const Clock = styled(Paper)(() => ({
  fontFamily: 'roboto',
  backgroundColor: 'black',
}));

const Time = styled(Typography)(() => ({
  fontWeight: 100,
  fontSize: '25vw',
  transition: '.3s all ease 0s',
  color: blueGrey[500],
}));

const TimeSecond = styled(Typography)(() => ({
  display: 'inline',
  fontWeight: 100,
  fontSize: '2vw',
  transition: '.3s all ease 0s',
  color: blueGrey[900],
}));

const DateTypography = styled(Typography)(() => ({
  fontWeight: 100,
  fontSize: '2vw',
  textAlign: 'center',
  transition: '.3s all ease 0s',
  color: blueGrey[700],
}));

const PageDitalClock = () => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setTime(time + 1);
    }, 200);

    // アンマウント時にクリア
    return () => {
      clearInterval(id);
    }
  }, [time]);

  const getDate = () => {
    const time: any = serializeTime(getCurrentTime());
    return time.year + '/' +
      prependZero(time.month) + '/' +
      prependZero(time.date) + ' (' +
      getDayOfWeekString(time.dayOfWeek) + ')';
  }
  const getHourMinute = () => {
    const time: any = serializeTime(getCurrentTime());
    return prependZero(time.hours) + ':' + prependZero(time.minutes);
  }

  const getSeconds = () => {
    const time: any = serializeTime(getCurrentTime());
    return prependZero(time.seconds);
  }

  const getCurrentTime = () => {
    return new Date();
  }

  const serializeTime = (time: Date) => {
    return {
      year: time.getFullYear(),
      month: time.getMonth() + 1,
      date: time.getDate(),
      dayOfWeek: time.getDay(),
      hours: time.getHours(),
      minutes: time.getMinutes(),
      seconds: time.getSeconds(),
    };
  };

  const prependZero = (num: string) => {
    const n = parseInt(num, 10);
    if (n >= 10) {
      return n;
    }
    return '0' + n;
  }

  const getDayOfWeekString = (dayOfWeek: number) => {
    return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][dayOfWeek];
  }

  return (
    <Container>
      <Clock>
        <Time>
          {getHourMinute()}
          <TimeSecond>
            {getSeconds()}
          </TimeSecond>
        </Time>
        <DateTypography>
          {getDate()}
        </DateTypography>
      </Clock>
    </Container>
  );
}

export default PageDitalClock;
