import React, {useState} from 'react';
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";

type Props = {
  id: string,
  value: number,
  title: string,
  focused?: boolean,
  onChange: any,
}

const InputNumber: React.FC<Props> = ({id, value, title, onChange, focused = false}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let num = Number.parseInt(event.target.value);
    if (isNaN(num)) {
      num = 0;
    }
    setInputValue(num);
    onChange(id, num);
  }

  return (
    <React.Fragment>
      <FormControl variant="standard" sx={{m: 1, mt: 3, width: '12ch'}}>
        <Input
          id={id}
          value={value}
          autoFocus={focused}
          aria-describedby="standard-weight-helper-text"
          endAdornment={<InputAdornment position="end">{title}</InputAdornment>}
          onChange={handleChange}
          inputProps={{
            'aria-label': title,
            pattern: "[0-9]*",
          }}
        />
      </FormControl>
    </React.Fragment>
  )
}

export {InputNumber};
