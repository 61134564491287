import React from 'react';

const styleInner = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  justifyContent: 'center',
  height: '100vh',
}

type Props = {
  children: any,
}

const ContainerTimer: React.FC<Props> = ({children}) => {
  return (
    <div style={styleInner}>
      {children}
    </div>
  );
}

export {ContainerTimer};
