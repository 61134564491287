import React, {useState} from 'react';
import {Modal, Box, Button} from '@mui/material';
import {InputNumber} from "./InputNumber";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  backgroundColor: '#eeee',
  border: '1px solid #000',
  p: 4,
};

type Props = {
  open: boolean,
  initialTime: number,
  setOpen: any,
  onChangeInitialTime: any,
}

type Time = {
  seconds: number,
  minutes: number,
  hours: number,
}

const SettingModal: React.FC<Props> = ({open, setOpen, initialTime, onChangeInitialTime}) => {

  const getInitialState = (): Time => {
    return {
      seconds: initialTime % 60,
      minutes: Math.floor(initialTime / 60) % 60,
      hours: Math.floor(initialTime / 3600),
    };
  }
  const [time, setTime] = useState(getInitialState());

  const handleClose = () => {
    onChangeInitialTime(time.seconds + time.minutes * 60 + time.hours * 3600);
    setOpen(false);
  }

  const handleCloseCancel = () => {
    setOpen(false);
  }

  const handleChange = (id: string, newValue: number): void => {
    let newTime = {...time};
    switch (id) {
      case 'seconds':
        newTime['seconds'] = newValue;
        break;
      case 'minutes':
        newTime['minutes'] = newValue;
        break;
      case 'hours':
        newTime['hours'] = newValue;
        break;
    }

    setTime(newTime);
  };


  console.log('time is');
  console.log(time);

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleCloseCancel}
      >
        <Box sx={style}>
          <InputNumber id={'hours'} value={time.hours} title={"時間"} onChange={handleChange}/>
          <InputNumber id={'minutes'} focused={true} value={time.minutes} title={"分"} onChange={handleChange}/>
          <InputNumber id={'seconds'} value={time.seconds} title={"秒"} onChange={handleChange}/>

          <hr/>
          <Button onClick={handleClose} variant="contained">設定</Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export {SettingModal};
