import React, {useEffect, useState} from 'react';
import {Button, Paper, Stack, styled} from '@mui/material';
import {TimePanel} from './TimePanel';
import {SettingModal} from './SettingModal/SettingModal';
import {ContainerTimer} from "./ContainerTimer";

type StateUnion = 'stop' | 'running' | 'pause' | 'alarm';
type State = {
  timerState: StateUnion,
  initial: number,
  count: number,
};

const initialState: State = {
  timerState: 'pause',
  initial: 300,
  count: 300,
};

const PageTimer = () => {
  const [state, setState] = useState(initialState);

  // スタート・ストップボタンをクリック
  const onStart = () => {
    setState((state) => {
      let newState = {...state};
      if (state.timerState === 'alarm') {
        newState.count = state.initial;
      }
      newState.timerState = (state.timerState === 'running') ? 'pause' : 'running';

      return newState;
    });
  }

  // クリアボタンをクリック
  const onClear = () => {
    setState((state) => {
      return {...state, count: state.initial}
    });
  }

  // 設定ボタンをクリック
  const onSetting = () => {
    setOpen(true);
  }

  // 初期時間の変更
  const onChangeInitialTime = (newInitialTime: number) => {
    console.log(newInitialTime);
    setState((state) => {
      return {
        ...state,
        initial: newInitialTime,
        count: newInitialTime,
      };
    });
  }

  // タイマーの更新制御
  const [time, setTime] = useState(0);
  useEffect(() => {
    const id = setInterval(() => onUpdate(), 1000);
    return () => {
      clearInterval(id);
    };
  }, [time]);

  // 更新処理
  const onUpdate = () => {
    setState((state) => {
      let newState = {...state};
      if (state.timerState === 'running') {
        newState.count--;
      }
      if (newState.count <= 0) {
        newState.timerState = 'alarm';
      }

      return newState;
    });
  };

  const isRunning = state.timerState === 'running'

  // モーダルの表示
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <ContainerTimer>
        <TimePanel restTimeSec={state.count}/>

        <Stack spacing={2} direction={'row'} justifyContent={'center'}>
          <Button onClick={onStart} variant="contained">{isRunning ? 'ストップ' : 'スタート'}</Button>
          <Button onClick={onClear} disabled={isRunning} variant="contained">クリア</Button>
          <Button onClick={onSetting} disabled={isRunning} variant="contained">セット</Button>
        </Stack>
      </ContainerTimer>

      <SettingModal
        open={open}
        setOpen={setOpen}
        initialTime={state.initial}
        onChangeInitialTime={onChangeInitialTime}/>
    </React.Fragment>
  );
}

export {PageTimer};
