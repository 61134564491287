import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import './App.css';
import PageBmi from './health/bmi/PageBmi';
import PageDigitalClock from './time/digital/PageDititalClock';
import { PageTimer } from './time/timer/PageTimer';
import {CssBaseline} from "@mui/material";

function Home() {
  return (
      <div>
        home page
        <ul>
          <li>
            時刻・時計
            <ul>
              <li>
                <Link to="/time/digital">デジタル時計</Link>
              </li>
              <li>
                <Link to="/time/timer">タイマー</Link>
              </li>
            </ul>
          </li>
          <li>
            健康
            <ul>
              <li>
                <Link to="/health/bmi">BMI 計算機</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
  );
}


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <CssBaseline />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="health/bmi" element={<PageBmi />} />
          <Route path="time/digital" element={<PageDigitalClock />} />
          <Route path="time/timer" element={<PageTimer />} />
        </Routes>

      </header>
    </div>
  );
}

export default App;
