
import { AppBar, Toolbar, Typography } from '@mui/material';
import React from 'react';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';


const Header = () => {
    return (
        <AppBar position='static'>
            <Toolbar>
                <Typography>
                    <FitnessCenterIcon></FitnessCenterIcon>
                    　BMI 計算機
                </Typography>
            </Toolbar>
        </AppBar>
    );
}

export default Header;